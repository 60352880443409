<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', currentTabIndex === 0)" /> Pendings
        </template>
        <SchedulingPending
          ref="ssPending"
          :tab-index="currentTabIndex"
          @show="showScheduling"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-history', currentTabIndex === 1)" /> Histories
        </template>
        <SchedulingHistory
          ref="ssHistory"
          :tab-index="currentTabIndex"
          @show="showScheduling"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalSchedule"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-stack-overflow-line h4" />
              <b class="is-tab-active">Products</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">

            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="PO Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.purchase_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Transfer Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.transfer_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Deliver To"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.destination_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Delivery From"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.origin_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Group"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.group_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Remarks"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.remarks || 'no remarks'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <b-table
                ref="tableProducts"
                small
                hover
                striped
                bordered
                responsive
                show-empty
                class="mb-0"
                :items="stockRequest.details"
                :busy="tableProducts.busy"
                :filter="tableProducts.filter"
                :fields="tableProducts.fields"
                :stacked="tableProducts.stacked"
                :filter-included-fields="tableProducts.filterOn"
              >
                <template #cell(index)="{ index }">
                  <div
                    class="text-md-nowrap"
                    v-text="tableRowNumber(tableProducts, index)"
                  />
                </template>

                <template #cell()="{ value }">
                  <div
                    class="text-md-nowrap d-flex align-items-center min-h-35px px-1"
                    v-text="value"
                  />
                </template>

                <template #cell(product_name)="{ value }">
                  <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
                    <strong
                      class="font-weight-bolder"
                      v-text="value"
                    />
                  </div>
                </template>

                <template #cell(posting_quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="value"
                    />
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-todo-line h4" />
              <b class="is-tab-active">Delivery Details</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label-for="fCustomerDirect"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      id="fCustomerDirect"
                      v-model="stockRequest.delivery_details.customer_direct"
                      switch
                      value="1"
                      name="customer_direct"
                      unchecked-value="0"
                      disabled
                    >
                      <strong>Direct Deliver to Customer?</strong>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvCustomerMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerMobile"
                      v-model="stockRequest.delivery_details.mobile_number"
                      type="text"
                      placeholder="no customer mobile"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Name"
                    label-for="fvCustomerName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerName"
                      v-model="stockRequest.delivery_details.customer"
                      type="text"
                      placeholder="no customer name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Address"
                    label-for="fvCustomerAddress"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="fvCustomerAddress"
                      v-model="stockRequest.delivery_details.address"
                      rows="4"
                      max-rows="6"
                      maxlength="50"
                      placeholder="no customer address"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i
                :class="['ri-calendar-line h4', {
                  'text-danger fat fa-shake': vTabDeliverySchedule()
                }]"
              />
              <b
                :class="['is-tab-active ml-1', {
                  'text-danger': vTabDeliverySchedule()
                }]"
              >
                Delivery Schedule
              </b>
            </div>
          </template>

          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <ValidationObserver
                ref="formSchedule"
              >
                <b-row>

                  <b-col
                    cols="12"
                    md="3"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="schedule_details.trip_type"
                      name="trip type"
                      rules="required"
                    >
                      <b-form-group
                        label="Trip Type"
                        label-for="fTripType"
                        label-class="font-weight-bolder"
                      >
                        <b-form-select
                          id="fTripType"
                          v-model="stockRequest.schedule_details.trip_type"
                          :options="options.tripTypes"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(!state.scheduling)"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select trip type here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="3"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="schedule_details.date"
                      name="date"
                      rules="required"
                    >
                      <b-form-group
                        :label="tripTypeLabel"
                        label-for="fScheduleDate"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fScheduleDate"
                          v-model="stockRequest.schedule_details.date"
                          type="date"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(!state.scheduling)"
                        />
                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                        md="6"
                        class="px-1 px-md-2"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="schedule_details.driver"
                          name="driver"
                          rules="required"
                        >
                          <b-form-group
                            label="Driver"
                            label-for="fScheduleDriver"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <v-select
                              v-model="selected.driver"
                              input-id="fScheduleDriver"
                              placeholder="search driver here"
                              label="driver_name"
                              clearable
                              append-to-body
                              :options="(options.drivers)"
                              :loading="(state.busy || fetching.drivers)"
                              :disabled="(!state.scheduling || fetching.drivers)"
                              :calculate-position="calculateDropPosition"
                              :class="{ 'is-invalid': errors.length > 0 }"
                            >
                              <template #option="{ driver_name }">
                                <div
                                  class="py-1"
                                  v-text="driver_name"
                                />
                              </template>
                              <template #no-options="">
                                no available driver
                              </template>
                            </v-select>
                            <div
                              v-if="(errors.length > 0)"
                              class="invalid-feedback"
                              v-text="errors[0]"
                            />
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                        class="px-1 px-md-2"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="schedule_details.mobile_number"
                          name="mobile number"
                          rules="max:15"
                        >
                          <b-form-group
                            label="Mobile Number"
                            label-for="fScheduleMobile"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="fScheduleMobile"
                              v-model="stockRequest.schedule_details.mobile_number"
                              type="text"
                              maxlength="15"
                              placeholder="no mobile number"
                              :state="(errors.length > 0 ? false : null)"
                              disabled
                            />
                            <div
                              v-if="(errors.length > 0)"
                              class="invalid-feedback"
                              v-text="errors[0]"
                            />
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="schedule_details.helper"
                      name="helper"
                      rules="max:50"
                    >
                      <b-form-group
                        label="Helper"
                        label-for="fScheduleHelper"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fScheduleHelper"
                          v-model="stockRequest.schedule_details.helper"
                          type="text"
                          maxlength="50"
                          :placeholder="(currentTabIndex === 1 ? 'no helper' : 'enter helper here')"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(!state.scheduling)"
                        />
                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <b-row>

                      <b-col
                        cols="12"
                        md="6"
                        class="px-1 px-md-2"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="schedule_details.vehicle"
                          name="vehicle"
                          rules="required"
                        >
                          <b-form-group
                            label="Vehicle"
                            label-for="fScheduleVehicle"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <v-select
                              v-model="selected.vehicle"
                              input-id="fScheduleVehicle"
                              placeholder="search vehicle here"
                              label="vehicle_plate"
                              clearable
                              append-to-body
                              :options="(options.vehicles)"
                              :loading="(state.busy || fetching.vehicles)"
                              :disabled="(!state.scheduling || fetching.vehicles)"
                              :calculate-position="calculateDropPosition"
                              :class="{ 'is-invalid': errors.length > 0 }"
                            >
                              <template #option="{ vehicle_plate, vehicle_name }">
                                <div class="d-flex flex-column py-1">
                                  <span
                                    class="text-wrap"
                                    v-text="vehicle_name"
                                  />
                                  <strong v-text="vehicle_plate" />
                                </div>
                              </template>
                              <template #no-options="">
                                no available vehicle
                              </template>
                            </v-select>
                            <div
                              v-if="(errors.length > 0)"
                              class="invalid-feedback"
                              v-text="errors[0]"
                            />
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                        class="px-1 px-md-2"
                      >
                        <b-form-group
                          label="Vehicle Name"
                          label-for="fScheduleVehicleName"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <b-input
                            id="fScheduleVehicleName"
                            v-model="stockRequest.schedule_details.vehicle_name"
                            placeholder="no vehicle name"
                            type="text"
                            disabled
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-col>

          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-history-line h4" />
              <b class="is-tab-active ml-1">Tracking</b>
            </div>
          </template>

          <Timeline
            :stock-request="stockRequest"
          >
            <template #approvals="request">
              <Signatory
                :stock-request="request"
                :approver="requesterSignatory"
              />
              <Signatory
                v-for="(approver, key) in request.approvals"
                :key="key"
                :stock-request="request"
                :approver="approver"
              />
            </template>
          </Timeline>
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="flex-grow-1 d-flex justify-content-between mb-0 pb-0">
          <div />
          <div>
            <b-button
              v-if="state.scheduling"
              variant="primary"
              class="mr-2"
              :disabled="state.busy"
              @click.prevent="$event => validateScheduling($event)"
            >
              Set Schedule
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
// import FUSE from 'fuse.js'
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SSharedList } from '@/services'

import SchedulingPending from '@/views/lo/scheduling/Pending'
import SchedulingHistory from '@/views/lo/scheduling/History'
import Timeline from '@/components/custom/timeline/Base'
import Signatory from '@/components/custom/timeline/Signatory'

export default {

  name: 'LogisticsOfficerSchedulingBase',

  middleware: ['auth', 'lo'],

  metaInfo: () => ({
    title: 'Scheduling'
  }),

  components: {
    SchedulingPending,
    SchedulingHistory,
    Timeline,
    Signatory
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  data () {
    return {
      currentTabIndex: this.getCurrentTabIndexByURL(),
      state: {
        busy: false,
        scheduling: false
      },
      fetching: {
        vehicles: false,
        drivers: false
      },
      options: {
        tripTypes: [
          'Delivery',
          'Pick-Up',
          'Inter Branch'
        ],
        vehicles: [],
        drivers: []
      },
      selected: {
        vehicle: null,
        driver: null
      },
      stockRequest: {
        id: null,
        stage: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          destination: null,
          destination_name: null,
          destination_code: null,
          origin: null,
          origin_name: null,
          origin_code: null,
          group: null,
          group_name: null,
          group_code: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          address: null,
          mobile_number: null
        },
        schedule_details: {
          date: null,
          trip_type: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: [],
        approvals: [],
        remarks: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      tableProducts: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'posting_quantity', label: 'Quantity', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    tripTypeLabel () {
      return `${this.stockRequest.schedule_details.trip_type || ''} Date`
    }
  },

  watch: {
    'selected.vehicle' (vehicle) {
      this.stockRequest.schedule_details.vehicle = vehicle?.id || null
      this.stockRequest.schedule_details.vehicle_plate = vehicle?.vehicle_plate || null
      this.stockRequest.schedule_details.vehicle_name = vehicle?.vehicle_name || null
    },

    'selected.driver' (driver) {
      this.stockRequest.schedule_details.driver = driver?.id || null
      this.stockRequest.schedule_details.driver_name = driver?.driver_name || null
      this.stockRequest.schedule_details.mobile_number = driver?.mobile_number || null
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    vTabDeliverySchedule () {
      return this.$refs.formSchedule && Object.keys(this.$refs.formSchedule.errors).find(
        key => this.$refs.formSchedule.errors[key]?.length > 0
      )
    },

    getCurrentTabIndexByURL () {
      switch (this.$route.params.scheduler) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    getVehicles () {
      this.fetching.vehicles = true
      return SSharedList.getVehicles().then(({ data }) => {
        this.options.vehicles = data
      }).catch(() => {
        this.options.vehicles = []
      }).finally(() => {
        this.fetching.vehicles = false
      })
    },

    getDrivers () {
      this.fetching.drivers = true
      return SSharedList.getDrivers().then(({ data }) => {
        this.options.drivers = data
      }).catch(() => {
        this.options.drivers = []
      }).finally(() => {
        this.fetching.drivers = false
      })
    },

    showScheduling (stockRequest, useScheduling) {
      this.state.scheduling = useScheduling

      const { id, approvable } = stockRequest
      const { delivery_details, schedule_details } = approvable

      this.stockRequest = Object.assign(this.stockRequest, {
        id: id,
        stage: approvable.stage,
        references: {
          document_number: approvable.document_number,
          transfer_number: approvable.transfer_number,
          sap_doc_entry: approvable.sap_doc_entry,
          purchase_number: approvable.purchase_number
        },
        headers: {
          destination: approvable.destination_id,
          destination_name: approvable.destination_name,
          destination_code: approvable.destination_code,
          origin: approvable.origin_id,
          origin_code: approvable.origin_code,
          origin_name: approvable.origin_name,
          group: approvable.group_id,
          group_code: approvable.group_code,
          group_name: approvable.group_name
        },
        delivery_details: {
          customer_direct: Number(approvable.customer_direct),
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        schedule_details: {
          date: schedule_details?.date || null,
          trip_type: schedule_details?.trip_type || null,
          vehicle: schedule_details?.vehicle || null,
          vehicle_plate: schedule_details?.vehicle_plate || null,
          vehicle_name: schedule_details?.vehicle_name || null,
          driver: schedule_details?.driver || null,
          driver_name: schedule_details?.driver_name || null,
          mobile_number: schedule_details?.mobile_number || null,
          helper: schedule_details?.helper || null
        },
        details: approvable.transfer_details,
        approvals: approvable.approvals,
        remarks: approvable.remarks,
        created_at: approvable.created_at
      })

      this.requesterSignatory = Object.assign(this.requesterSignatory, {
        approver_name: approvable.destination_name,
        status_at: approvable.created_at
      })

      this.getVehicles().finally(() => {
        this.selected.vehicle = QUERY.find(this.options.vehicles, {
          id: Number(schedule_details?.vehicle)
        })

        this.getDrivers().finally(() => {
          this.selected.driver = QUERY.find(this.options.drivers, {
            id: Number(schedule_details?.driver)
          })
        })
      })

      this.$bvModal.show('modalSchedule')
    },

    validateScheduling ({ target }) {
      this.$refs.formSchedule.validate().then(
        validSchedule => {
          if (!validSchedule) {
            return this.swalInvalid()
          }

          this.swalConfirmWithRemarks({
            html: 'Set the Delivery Schedule?',
            preConfirm: remarks => {
              return this.$refs.ssPending.update({
                id: this.stockRequest.id,
                selected: [
                  {
                    id: this.stockRequest.id,
                    remarks: remarks
                  }
                ],
                schedule_details: this.stockRequest.schedule_details,
                status: 'Scheduled'
              }).catch(errors => {
                this.$refs.formSchedule.setErrors(errors)
              })
            },
            confirmButtonText: target.innerText
          })
        }
      )
    }
  }
}
</script>
